import React from 'react';
import './popup.style.css';

const Popup = ({ title, content, onClose }) => {
    return (
        <div className="popup-position-for-service">
            <div className="popup-wrapper-for-service animated slideInDown">
                <div>
                    <h2>{title}</h2>
                    <div className="popup-content">
                        {content}
                    </div>
                    <button className="cancel-btn" onClick={() => onClose()}>&times;</button>
                </div>
            </div>
        </div>
    )
}

export default Popup;