import React, { useState } from 'react';
import './carousel.style.css';
import { withRouter } from 'react-router-dom';

import 'swiper/swiper-bundle.css';
import Swiper from 'react-id-swiper';

import image1 from '../../images/engine.jpg';
import image2 from '../../images/image.jpg';

const params = {
    containerClass: 'swiper-container slide',
    parallax: true,
    centeredSlides: true,
    grabCursor: true,
    speed: 500,
    spaceBetween: 0,
    effect: 'slide',
    autoplay: {
        delay: 2500,
        disableOnInteraction: false
    }
}

// const params = {
//     spaceBetween: 30,
//     centeredSlides: true,
//     autoplay: {
//         delay: 2500,
//         disableOnInteraction: false
//     },
//     pagination: {
//         el: '.swiper-pagination',
//         clickable: true
//     },
//     navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev'
//     }
// }

const HeroSlider = (props) => {
  const [parallaxSwiper, setParallaxSwiper] = useState(null);
  const parallaxAmount = parallaxSwiper ? parallaxSwiper.width * 0.95 : 0;
  const parallaxOpacity = 0.5;

  const onRoute = (path) => {
    props.history.push(path);
  }

  return (
    <Swiper {...params} getSwiper={setParallaxSwiper}>
      <div className="slide">
        <div
          className="slide-image"
          data-swiper-parallax={parallaxAmount}
          data-swiper-parallax-opacity={parallaxOpacity}
        >
          <div className='slide-img' style={{backgroundImage: `url(${image1})`}} />
        </div>
        <div className="content">
            <div className="header-middle">
                <h1>Best Services, <span>Best Support</span></h1>
                <button className="btn-1" onClick={() => onRoute('/services')}>READ MORE</button>
            </div>
        </div>
      </div>
      <div className="slide">
        <div
          className="slide-image"
          data-swiper-parallax={parallaxAmount}
          data-swiper-parallax-opacity={parallaxOpacity}
        >
          <div className='slide-img' style={{backgroundImage: `url(${image2})`}} />
        </div>
        <div className="content">
            <div className="header-middle">
                <h1>More Than <span>20+ Services</span></h1>
                <button className="btn-1" onClick={() => onRoute('/services')}>READ MORE</button>
            </div>
        </div>
      </div>
    </Swiper>
  );
};

export default withRouter(HeroSlider);