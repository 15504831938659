import React, { Component } from 'react';
import './footer.style.css';
import { withRouter } from 'react-router-dom';

const Footer = (props) => {

    const onRoute = (path) => {
        props.history.push(path);
    }

    return (
        <div className="footer">
            <div className="content">
            <div className="footer-content-side-box">
                <h3>Checkin</h3>
                <p>Leading auto services, maintenance and repair company with a diverse portfolio of services for individuals & companies 
in the automotive and repair industry.</p>
            </div>
                <div className="footer-content-side-box">
                    <h3>Company</h3>
                    <a onClick={() => onRoute('/about')}>About Us</a>
                    <a onClick={() => onRoute('/services')}>Services</a>
                    {/* <a href="careers">Careers</a> */}
                    {/* <a href="mycheckin">myCheckin</a> */}
                </div>
                <div className="footer-content-side-box">
                    <h3>Our Services</h3>
                    <p>Regular Maintenance</p>
                    <p>Diagnostics & Inspections</p>
                    <p>Car Wash</p>
                    <p>Electric service</p>
                    <p>Mechanics</p>
                </div>
                <div className="footer-content-side-box">
                    <h3>Contact</h3>
                    <p>info@checkin-lb.com</p>
                    <p>+961 70 220 733</p>
                    <p>+961 08 512 401</p>
                    <p>Bar Elias - Damascus Road</p>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Footer);