import React from 'react';

import Topnav from '../../components/topnav/topnav.component';
import MobileMenu from '../../components/mobile-menu/mobile-menu.component';
import PageTitle from '../../components/page-title/page-title.component';
import Contact from '../../components/contact/contact.component';

import Footer from '../../components/footer/footer.component';
import BelowFooter from '../../components/below-footer/below-footer.compoent';

const ContactPage = () => {
  return (
    <div>

      <Topnav
        isAbsolute={false}
      />

      <MobileMenu />

      <PageTitle
        primaryTitle={'Need help?'}
        secondaryTitle={'Contact us'}
      />

      <Contact />

      <Footer />
      <BelowFooter />

    </div>
  );
}

export default ContactPage;