import React from 'react';
import './below-footer.style.css';

const BelowFooter = () => {
    return (
        <div className="below-footer">
            <div className="below-footer-content">
                <p>Copyright &copy; 2020 Checkin | All Rights Reserved | By <a href="https://divlp.com">divlp</a></p>
            </div>
        </div>
    )
}

export default BelowFooter;