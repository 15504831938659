import axios from 'axios';
import { API_PATH } from '../../config/endpoints';
import { RES_CONTACT_US } from '../resurces';

const contactUsApi = async (data) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${API_PATH + RES_CONTACT_US}`,
            headers: { 'content-type': 'application/json' },
            data: data
        });
        console.log('RESPONSE', response);
        return {
            status: 'SUCCESS'
        }
    } catch (error) {
        console.log('ERROR', error);
        return {
            status: 'ERROR'
        }
    }
}

export default contactUsApi;