import React, { useState } from 'react';
import './contact.style.css';

import { validateEmail } from '../../utils/validateEmail';

import contactUsApi from '../../apis/contact-us/contactUsApi';

const Contact = () => {
    let initial_state = {
        fname: {value: '', error: ''},
        lname: {value: '', error: ''},
        email: {value: '', error: ''},
        message: {value: '', error: ''},
        error: '',
        loading: false
    }
    const [state, setState] = useState(initial_state);
    const [mailSent, setMailSent] = useState(false);

    const checkNotEmpty = () => {
        if (state.fname.value !== '' && state.lname.value !== '' && state.email.value !== '' && state.message.value !== '') {
            return true;
        } else {
            return false;
        }
    }

    const checkInputsAreCorrect = () => {
        console.log('VALIDATTION', validateEmail(state.email.value));
        let status;
        if (!validateEmail(state.email.value)) {
            status = false;
        } else {
            return true;
        }
        return status
    }

    const sendMail = async () => {
        if (checkNotEmpty()) {
            if (checkInputsAreCorrect()) {
                setState({
                    ...state,
                    loading: true
                });
                let data = {
                    fname: state.fname.value,
                    lname: state.lname.value,
                    email: state.email.value,
                    message: state.message.value,
                }

                let respose = await contactUsApi(data);
                if (respose.status == 'SUCCESS') {
                    setState(initial_state);
                    setMailSent(true);
                } else {
                    setState({
                        ...state,
                        error: 'Something went wrong please try again later',
                        loading: false
                    });
                }
                console.log('response', respose);
            } else {
                setState({
                    ...state,
                    email: {
                        ...state.email,
                        error: `(${state.email.value}) is not a valid email.`
                    },
                    loading: false
                });
            }
        } else {
            setState({
                ...state,
                error: 'Some fields are empty',
                loading: false
            });
        }
    }

    const onChange = (input) => {
        let index = input.name;
        let value = input.value;
        setState({
            ...state,
            [index]: {
                value: value,
                error: ''
            },
            error: ''
        });
    }

    return (
        <div className="contact">
            <div className="content">

            {mailSent &&
                <div className='thanks'>
                    <p>Thank you for getting in touch with us one of our colleagues will get back to you shortly.</p>
                    <p>Have a great day</p>
                </div>
            }

            <h2></h2>
                <div className="contact-box">
                    <div className="contact-box-content">
                        <h1 className="animated fadeInLeft">Get in touch</h1>
                            <input 
                                type="text" 
                                placeholder="First name"
                                className="contact_input"
                                value={state.fname.value}
                                name={'fname'}
                                onChange={(event) => onChange(event.target)}
                            />
                            <input 
                                type="text" 
                                placeholder="Last Name" 
                                className="contact_input"
                                value={state.lname.value}
                                name={'lname'}
                                onChange={(event) => onChange(event.target)}
                            />
                            <input 
                                type="text" 
                                placeholder="Email"
                                className="contact_input"
                                value={state.email.value}
                                name={'email'}
                                onChange={(event) => onChange(event.target)}
                            />
                            {state.email.error !== '' &&
                                <div className="errors">{state.email.error}</div>
                            }

                            <textarea 
                                placeholder="Message"
                                value={state.message.value}
                                name={'message'}
                                onChange={(event) => onChange(event.target)}
                            />

                            {state.error !== '' &&
                                <div className="errors">{state.error}</div>
                            }

                            <button 
                                className="btn-1 animated zoomIn" 
                                onClick={state.loading ? () => {} : () => sendMail()}
                            >{state.loading ? 'Sending...' : 'Send'}
                            </button>
                    </div>
                </div>
                <div className="contact-box">
                <div className="contact-box-content">
                    <h1 className="animated fadeInLeft">Connect with us:</h1>
                    <p>We are here to answer any question you want to ask</p>
                    <p>EMAIL: info@checkin-lb.com</p>
                    <p>MOBILE: +961 70220733</p>
                    <p>LOCATION: Bar Elias Main Road</p>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;