import React from 'react';
import './topnav.style.css';

import { NavLink } from 'react-router-dom';

function Topnav({ isAbsolute }) {
    return (
        <div 
            className={`topnav ${isAbsolute ? 'topnav-absolute' : 'topnav-normal'}`}
        >
            <div className="content">
                <img className="logo" src="logo.png" alt="Checkin"/>
                <NavLink activeClassName="activeA" exact to="/">Home</NavLink>
                <NavLink activeClassName="activeA" exact to="services">Services</NavLink>
                {/* <NavLink activeClassName="activeA" exact to="careers">Careers</NavLink> */}
                <NavLink activeClassName="activeA" exact to="about">About</NavLink>
                <NavLink activeClassName="activeA" exact to="contact">Contact</NavLink>
            </div>
        </div>
    );
}

export default Topnav;