import React, { useState } from 'react';
import './services.styles.css';
import { services } from '../../content/text';

import Popup from '../popup/popup.comonent';

const Services = () => {
    const [state, setState] = useState({
        isPopUpShown: false,
        title: '',
        notes: ''
    });
    const showPopup = (title, notes) => {
        setState({
            ...state,
            isPopUpShown: true,
            title,
            notes
        });
    }
    const closePopup = () => {
        setState({
            ...state,
            isPopUpShown: false,
            title: '',
            notes: ''
        });
    }

    const renderList = (notes) => {
        let list = notes.map((item, key) =>
            <li key={key}>{item.text}</li>
        );
        return (
            <ul>
                {list}
            </ul>
        )
    }

    return (
        <>
        <div className="services box-padding">
            <div className="content">

                {services.map((data, key) => {
                    return (
                        <div className="services-box-wrapper" key={key}>
                            <div className="services-box">
                                <img className="image" src={data.img} />
                                <h2>{data.title}</h2>
                                <div className='text-wrapper'>
                                    <div className='text-wrapper-layer' />
                                    {renderList(data.notes)}
                                </div>
                                <button className="ref-1" onClick={() => showPopup(data.title, data.notes)}>Read more...</button>
                            </div>
                        </div>
                    )
                })}
                
            </div>
        </div>

        {state.isPopUpShown &&
            <Popup
                title={state.title}
                onClose={() => closePopup()}
                content={renderList(state.notes)}
            />
        }
        </>
    )
}

export default Services;