import React, { useState, useEffect } from 'react';
import './move-top.style.css';
import { icons } from '../../constants/icons';

const MoveTop = () => {
    const { ARROW_TOP } = icons;
    const [addedClass, setAddedClass] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const handleScroll = () => {
        let topScroll = window.pageYOffset;
        if (topScroll >= 100) {
            setAddedClass('showMoveTop');
        } else {
            setAddedClass('');
        }
    }

    const onScrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <div
            onClick={() => onScrollTop()}
            className={`move_top_wrap animated slideInRight ${addedClass}`}
        >
            <div><img src={ARROW_TOP}/></div>
        </div>
    )
}

export default MoveTop;