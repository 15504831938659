import React from 'react';
import './header.style.css';
import Tips from '../tips/tips.component';
import Topnav from '../topnav/topnav.component';
import MobileMenu from '../mobile-menu/mobile-menu.component';
import Carousel from '../carousel/carousel.component';

function Header() {
    return (
        <div className="header">
            <Topnav
                isAbsolute={true}
            />

            <MobileMenu />

            {/* <div className="header-middle">
                <h1>Best Services, <span>Best Support</span></h1>
                <button>READ MORE</button>
            </div> */}

            <Carousel />

            <Tips />

        </div>
    )
}

export default Header;