import React, { useEffect } from 'react';
import Services from '../../components/services/services.component';

import Topnav from '../../components/topnav/topnav.component';
import MobileMenu from '../../components/mobile-menu/mobile-menu.component';
import PageTitle from '../../components/page-title/page-title.component';

import Footer from '../../components/footer/footer.component';
import BelowFooter from '../../components/below-footer/below-footer.compoent';

const ServicePage = (props) => {

  useEffect(() => {
    if (props.location.pathname == '/services') {
      window.scrollTo(0, 0)
    }
  }, []);

  return (
    <div>

      <Topnav
        isAbsolute={false}
      />

      <MobileMenu />

      <PageTitle
        primaryTitle={'We offer wide range of'}
        secondaryTitle={'Services.'}
      />

      <Services />

      <Footer />
      <BelowFooter />

    </div>
  );
}

export default ServicePage;