import React, { useState } from 'react';
import './faq.style.css';
import { faqs } from '../../content/text';

const Faq = () => {
    const [state, setState] = useState({
        active: null
    });
    const toggle = (position) => {
        if (state.active === position) {
            setState({ active : null });
        } else {
            setState({ active : position });
        }
    }
    const displayBox = (position) => {
        if (state.active === position) {
            return "block";
        } else {
            return "none";
        }
    }
    const srcImage = (position) => {
        if (state.active === position) {
            return "minus";
        } else {
            return "plus";
        }
    }
    return (
        <div className="faq container box-padding">
            <div className="content">
                <h1 className="big-font">Frequently Asked Questions</h1>
                {faqs.map((item, key) => {
                    return (
                        <div className="faq-box" key={key}>
                            <div onClick={() => toggle(key)}>
                                <h2 className="medium-font">{item.question}</h2>
                                <span className={srcImage(key) }></span>
                            </div>
                            <p className="medium-font" style={{display: displayBox(key) }} >&lowast; {item.answer}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Faq;