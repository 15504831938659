import React from 'react';
import './about.style.css';

import { icons } from '../../constants/icons';
import { about } from '../../content/text';

const About = () => {
    const { BEST, SHAKE } = icons;

    return (
        <div className="about box-padding">
            <div className="content">
                <div className="about-box">
                    <div className="about-box-content">
                        <div className="about-title">
                            <img src={BEST} />
                            <h1>Our History</h1>
                        </div>
                    <hr className="small-line" />
                    <p>{about.ABOUT_COMPANY}</p>
                    </div>
                </div>
                <div className="about-box">
                    <div className="about-box-content">
                        <div className="about-title">
                            <img src={SHAKE} />
                            <h1>Our Misson</h1>
                        </div>
                    <hr className="small-line" />
                    <p>{about.OUR_MISSON}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;