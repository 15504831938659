import React from 'react';

import Header from '../../components/header/header.component';
import CheckList from '../../components/check-list/check-list.compoent';
import AboutCompany from '../../components/about-company/about-company.component';
import FAQ from '../../components/faq/faq.component'; 
import SomeInfo from '../../components/some-info/some-info.component';
import MoveTop from '../../components/move-top/move-top.component';

import Footer from '../../components/footer/footer.component';
import BelowFooter from '../../components/below-footer/below-footer.compoent';

const HomePage = () => {
    return (
        <div>
            <Header />

            <hr className="wide-line" />

            <CheckList />

            <FAQ />

            <AboutCompany />

            <SomeInfo />

            <Footer />
            <BelowFooter />

            <MoveTop />

        </div>
    )
}

export default HomePage;