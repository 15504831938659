import React, { Component } from 'react';
import './check-list.style.css';
import { icons } from '../../constants/icons';
import { withRouter } from 'react-router-dom';

function CheckList(props) {
    const { CHECK } = icons;

    const onRoute = (path) => {
        props.history.push(path);
    }

    return (
        <div className="check-list container box-padding">
            <div className="content">
                <h1 className="big-font">We will take <span style={{color: '#F39C12'}}>care</span> of your drive.</h1>
                <h2>We offer a wide range of repair services to vehicle owners.</h2>
                <h2>Our team knows how to handle it.</h2>
                <div className="each-check"><img src={CHECK} />Car Repair</div>
                <div className="each-check"><img src={CHECK} />Car Mechanics</div>
                <div className="each-check"><img src={CHECK} />Car Report</div>
                <div className="each-check"><img src={CHECK} />Car Wash</div>
                <div className="each-check"><img src={CHECK} />Car Transmission</div>
                <div className="each-check"><img src={CHECK} />Car Engine</div>
                <div style={{margin: 10, color: '#aaa', fontWeight: 'bold'}}>Want to know more about our services?{' '}
                    <button 
                        className="btn-1" 
                        onClick={() => onRoute('/services')}
                    >READ MORE
                    </button>
                </div>
            </div>
        </div>
    );
}

export default withRouter(CheckList);