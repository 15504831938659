import React, { useState } from 'react';
import './mobile-menu.style.css';

import { NavLink } from 'react-router-dom';

const MobileMenu = () => {
    const [isNavShown, setIsNavShown] = useState(false);
    return (
        <>
        <div 
            className="mobile-menu container" 
            style={{position: 'normal'}} 
            onClick={() => setIsNavShown(!isNavShown)}
        >
            <div className="menuMobile" style={{backgroundColor: '#F39C12'}}>
                <div className={`menu-icon ${isNavShown ? 'nav-close' : 'nav-open'}`}></div>
            </div>
        </div>

        {isNavShown &&
            <div className="overlay">
                <div className="overlay-content">
                    <NavLink activeClassName="activeA" exact to="/">Home</NavLink>
                    <NavLink activeClassName="activeA" exact to="services">Services</NavLink>
                    {/* <NavLink activeClassName="activeA" exact to="careers">Careers</NavLink> */}
                    <NavLink activeClassName="activeA" exact to="about">About</NavLink>
                    <NavLink activeClassName="activeA" exact to="contact">Contact</NavLink>
                </div>
            </div>
        }
        </>
    )
}

export default MobileMenu;