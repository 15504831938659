import React from 'react';

import NotFound from '../../components/not-found/not-found';

const NotFoundPage = () => {
  return (
    <div>

      <NotFound />

    </div>
  );
}

export default NotFoundPage;