import React from 'react';
import './some-info.style.css';

function SomeInfo() {
    return (
        <div className="some-info container box-padding">
            <div className="wide-content">
                <h1>We have over 200 certified care partners.</h1>
                <div className="each-info"><p>35+</p><p>Happy Clients</p></div>
                <div className="each-info"><p>100+</p><p>Repaired Cars</p></div>
                <div className="each-info"><p>200+</p><p>Assignments Completed</p></div>
            </div>
        </div>
    )
}

export default SomeInfo;