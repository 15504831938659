import React from 'react';
import './about-company.style.css';
import { about } from '../../content/text';

const AboutCompany = () => {
    return (
        <div className="about-company">
            <div className="about-company-img">
                <div className="about-company-text">
                    <p>{about.ABOUT_COMPANY}</p>
                </div>
            </div>
        </div>
    )
}

export default AboutCompany;