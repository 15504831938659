const path = (value) => {
    return require(`../images/icons/${value}`);
}

const icons = {
    CAR_WASH: path('car-wash.png'),
    ENGINE: path('engine.png'),
    GEARS: path('gears.png'),
    TURBO: path('turbo.png'),
    CHECK: path('check.png'),
    PLUS: path('plus.png'),
    MINUS: path('minus.png'),
    NAV_OPEN: path('nav-open.png'),
    NAV_CLOSE: path('nav-close.png'),
    BEST: path('best.png'),
    SHAKE: path('shake.png'),
    ARROW_TOP: path('top-arrow.png')
}

export {
    icons
}