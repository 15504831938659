import React, { useEffect } from 'react';

import Topnav from '../../components/topnav/topnav.component';
import MobileMenu from '../../components/mobile-menu/mobile-menu.component';
import PageTitle from '../../components/page-title/page-title.component';

import About from '../../components/about/about.component';

import Footer from '../../components/footer/footer.component';
import BelowFooter from '../../components/below-footer/below-footer.compoent';

const AboutPage = (props) => {

  useEffect(() => {
    if (props.location.pathname == '/about') {
      window.scrollTo(0, 0)
    }
  }, []);

  return (
    <div>

      <Topnav
        isAbsolute={false}
      />

      <MobileMenu />

      <PageTitle
        primaryTitle={'Want to know more'}
        secondaryTitle={'about us?'}
      />

      <About />

      <Footer />
      <BelowFooter />

    </div>
  );
}

export default AboutPage;