import React from 'react';
import './page-title.style.css';

const PageTitle = ({
    primaryTitle,
    secondaryTitle
}) => {
    return (
        <div className="page-title box-padding">
            <div className="content">
                <h1 className="big-font">{primaryTitle}{' '}
                <span style={{color: '#F39C12'}}>{secondaryTitle}</span></h1>
            </div>
        </div>
    )
}

export default PageTitle;