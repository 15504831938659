import React from 'react';
import './tips.style.css';
import { icons } from '../../constants/icons';

function Tips() {
    const { CAR_WASH, ENGINE, GEARS, TURBO } = icons;
    const BACK_WHITE = {backgroundColor: 'white'};
    const BACK_GRAY = {backgroundColor: '#eee'};

    return (
        <div className="tips container box-padding">
            <div className="content">
            <div className="tips-box">
                <div className="tips-box-content" style={BACK_WHITE} >
                    <div className="img-wrapper"><img className="img" src={ENGINE} alt="Mission"/></div>
                    <p className="title">Management Vehicles</p>
                    <p className="par">for Companies, Organizations and more</p>
                </div>
            </div>
            <div className="tips-box">
                <div className="tips-box-content" style={BACK_GRAY} >
                    <div className="img-wrapper"><img className="img" src={CAR_WASH} alt="Service"/></div>
                    <p className="title">Best Services</p>
                    <p className="par">More Than 20+ Services</p>
                </div>
            </div>
            <div className="tips-box">
                <div className="tips-box-content" style={BACK_WHITE} >
                    <div className="img-wrapper"><img className="img" src={GEARS} alt="Technology"/></div>
                    <p className="title">High Technology</p>
                    <p className="par">Latest Equipments</p>
                </div>
            </div>
            <div className="tips-box">
                <div className="tips-box-content" style={BACK_GRAY} >
                    <div className="img-wrapper"><img className="img" src={TURBO} alt="Work"/></div>
                    <p className="title">Professional Work</p>
                    <p className="par">Highly Trained & Professional Staff</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Tips;