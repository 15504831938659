const about = {
    ABOUT_COMPANY: `Established in 2015, Checkin has successfully 
    become a leading auto services with a diverse portfolio 
    of services in the automotive and repair industry, which lead us to become one of the best in the country.`,
    OUR_MISSON: `Our dedication and hard work lead us to become best in the industry, our main goal is to do everything related to auto services, from buying the car to repair and
    maintenance, you don't have to think of anything, all you have to do is to drive your car & enjoy the rides.`
}

const tips = {
    CAR_WASH: `Our highly trained, professional staff goes through the most stringent of 
    training processes and reference checks. We use only the highest quality products and today’s most cutting edge and pioneering equipment.`,
}

const faqs = [
    {
        id: 1,
        question: 'What should I do to check my car?',
        answer: 'You can contact us, then we will give you a meeting date, or you can directly pass by',
    },
    {
        id: 2,
        question: 'What services does checkin provide?',
        answer: 'We have wide range of services, you can check them in \'Services\' link',
    },
    {
        id: 3,
        question: 'How can I know if my car is finished from the repair?',
        answer: 'Either you can wait in our coffee room or once done we can contact you',
    }
]

const services = [
    {
        id: 1,
        title: `Regular Maintenance`,
        notes: [
            {text: 'Checking oil levels'},
            {text: 'Checking all kinds of filter status'},
            {text: 'Examining the battery system'},
            {text: 'Cleaning & lubricating engine pieces'}
        ],
        img: require(`../images/icons/services.png`)
    },
    {
        id: 2,
        title: `Diagnostics & Inspections`,
        notes: [
            {text: 'Diagnosing lighting system'},
            {text: 'Testing proper functionality of brakes & repair'}
        ],
        img: require(`../images/icons/services.png`)
    },
    {
        id: 3,
        title: `Full Automotive Repair`,
        notes: [
            {text: 'Checking electronics & wiring'},
            {text: 'Repairing Interior/Exterior light system'},
            {text: 'Checking belt functionality'},
            {text: 'Checking cooling & air conditioning services & repair'},
        ],
        img: require(`../images/icons/services.png`)
    },
    {
        id: 4,
        title: `Mechanics`,
        notes: [
            {text: 'Diagnosing the problem accurately and quickly'},
            {text: 'Replacing certain parts if necessary'},
        ],
        img: require(`../images/icons/services.png`)
    },
    {
        id: 5,
        title: `Management Vehicles`,
        notes: [
            {text: 'Providing full managing services for companies, governments, organizations'},
            {text: 'Based on 1 year contract'},
            {text: 'Providing monthly checks'},
            {text: 'Providing the ability to track your car'},
        ],
        img: require(`../images/icons/services.png`)
    }
]

const jobs_data = [
    {
        id: 1,
        title: `We are currently looking for Mechanical Engineer`,
        jobTitle: `Mechanical Engineer`,
        responsibilites: `some data about the position`,
        education: `BS in Engineering`,
        experience: `1 year experience`
    },
    {
        id: 1,
        title: `We are currently looking for Mechanical Engineer`,
        jobTitle: `Mechanical Engineer`,
        responsibilites: `some data about the position`,
        education: `BS in Engineering`,
        experience: `1 year experience`
    },
    {
        id: 1,
        title: `We are currently looking for Mechanical Engineer`,
        jobTitle: `Mechanical Engineer`,
        responsibilites: `some data about the position`,
        education: `BS in Engineering`,
        experience: `1 year experience`
    },
    {
        id: 1,
        title: `We are currently looking for Mechanical Engineer`,
        jobTitle: `Mechanical Engineer`,
        responsibilites: `some data about the position`,
        education: `BS in Engineering`,
        experience: `1 year experience`
    }
]

export {
    about,
    tips,
    faqs,
    services,
    jobs_data
}