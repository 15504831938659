import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import HomePage from './pages/home';
import ServicePage  from './pages/services';
import CareerPage  from './pages/career';
import AboutPage  from './pages/about';
import ContactPage  from './pages/contact';
import NotFoundPage from './pages/common/not-found';

const App = (history) => {

  return (
    <div>
      <BrowserRouter basename='/'>
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/services" component={ServicePage} />
            {/* <Route path="/careers" component={CareerPage} /> */}
            <Route path="/about" component={AboutPage} />
            <Route path="/contact" component={ContactPage} />
            <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
